body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 13px;
  background-color: #ffffff;
  overflow-y: none;
}

button--large--cta {
  font-size: 14px;
}
img {
  margin: 0;
}
select {
  font-size: 14px;
  border: 0;
  background-color: #ffffff;
}
input {
  font-size: 14px;
  border: 0;
  background-color: #ffffff;
}
.login-input {
  font-size: 1.5em;
}
label {
  font-size: 14px;
  width: 30%;
}
a {
  color: #0617b1;
  text-decoration: none;
}
/* button {color: #FFFFFF; font-weight: 'normal'; font-size: 1em}
.btn-block {color: #FFFFFF; background-color: #061ef4; text-align: center; padding: 10px;border: none; font-weight: bold} */
.btn-block {
  color: #ffffff;
  background-color: #0617b1;
  text-align: center;
  padding: 10px;
  border: none;
  font-weight: bold;
  border-radius: 15px;
  width: 100%;
}
.btn-block-inv {
  color: #0617b1;
  background-color: #ffffff;
  text-align: center;
  padding: 10px;
  border: none;
  font-weight: bold;
  border: 1px solid #0617b1;
  border-radius: 15px;
  width: 100%;
}
.btn-small {
  color: #ffffff;
  background-color: #0617b1;
  text-align: center;
  padding: 0 10px;
  border: none;
  font-weight: bold;
  font-size: 0.75em;
  border-radius: 5px;
}
.btn-small-inv {
  color: #0617b1;
  background-color: #ffffff;
  text-align: center;
  padding: 0 10px;
  border: none;
  font-weight: bold;
  font-size: 0.75em;
  border: 1px solid #0617b1;
  border-radius: 5px;
}
.btn-medium {
  color: #ffffff;
  background-color: #0617b1;
  text-align: center;
  padding: 0 10px;
  border: none;
  font-weight: bold;
  font-size: 0.85em;
  border-radius: 12px;
}
.btn-medium-inv {
  color: #0617b1;
  background-color: #ffffff;
  text-align: center;
  padding: 0 10px;
  border: none;
  font-weight: bold;
  font-size: 0.85em;
  border: 1px solid #0617b1;
  border-radius: 12px;
}
.card {
  background-color: #ffffff;
  margin: 5px;
  padding: 10px;
}
.card-title {
  font-size: 10px;
  border-bottom: 1px solid #e8e5e5;
}
.tab-row {
  background-color: #ffffff;
  margin: 5px 0;
  padding: 0px;
}
.tab-col {
  padding: 10px;
  text-align: center;
}
/* .tab-col .active {display:inline-block;width:100%;height:100%; border-bottom:  3px solid #868686; font-weight: bold} */
.link {
  color: #0617b1;
}
.navbar {
  color: #000000;
}
.navbar.active {
  font-weight: bold;
}
.risk-info {
  line-height: 1.05em;
  font-size: 13px;
  color: #504b4b;
}
.risk-info-header {
  font-size: 12px;
  font-weight: bold;
  color: #696868;
}
.temblorheader {
  position: "absolute";
  top: "0px";
  left: "0px";
  margin: "0px";
  padding: "10px";
  height: "40px";
  width: "100%";
  background-color: "#FFFFFF";
}
a.mapboxgl-ctrl-logo {
  width: 133px;
  height: 40px;
  margin: 0 0 -3px -3px;
  /* display: none; */
  background-repeat: no-repeat;
  cursor: pointer;
  /* background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiP…gsOS40MyAxMC42Niw4LjUzIDExLjU2LDYuNjYgMTIuNDcsOC41MyAgICIvPjwvZz48L3N2Zz4=);  */
  background-image: url("./temblor-icon-logo.svg");
}

.bloglist {
  width: 100%;
  position: relative;
  list-style: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 0 0px;
  margin: 0 0 0px 0;
  color: #1f1f21;
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.bloglist > .list-item__center {
  padding: 0;
  line-height: 1em;
  min-height: 44px;
}
.page__content {
  padding-bottom: 44px;
  overflow: env(safe-area-inset-top);
}
figure {
  margin: 0;
  padding: 0;
}
figure img {
  margin: 0 -10px;
}
figure figcaption {
  font-size: 0.9em;
  color: #5c5a5a;
  line-height: 1.6em;
}

#navbar-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#navbar-ul li {
  float: left;
  margin: 0;
  margin-right: 5%;
}

#navbar-ul li a {
  display: block;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

/* .legend label,
   .legend span {
   display: block;
   float: left;
   height: 15px;
   width: 20%;
   text-align: center;
   font-size: 9px;
   color: #808080;
   padding: 0;
   margin-top: 3px;
   }

   .legendgear span {
   display: block;
   float: left;
   height: 15px;
   width: 8.33%;
   text-align: center;
   font-size: 9px;
   color: #808080;
   line-height: 120%;
   margin-top: 2px;
   margin-bottom: 5px;
   }
   .legendgear label {
   display: block;
   float: left;
   height: 15px;
   width: 14.28%;
   text-align: center;
   font-size: 9px;
   color: #808080;
   line-height: 120%;
   margin-top: 2px;
   padding: 0;
   }

   .legendtsunami span {
   display: block;
   float: left;
   height: 15px;
   width: 100%;
   text-align: center;
   font-size: 9px;
   color: #808080;
   }

   .legendwildfire span {
   display: block;
   float: left;
   height: 15px;
   width: 33%;
   text-align: center;
   font-size: 9px;
   color: #808080;
   line-height: 120%;
   margin-top: 2px;
   margin-bottom: 5px;
   }
   .legendwildfire label {
   display: block;
   float: left;
   height: 15px;
   width: 33%;
   text-align: center;
   font-size: 9px;
   color: #808080;
   line-height: 120%;
   margin-top: 2px;
   margin-bottom: 5px;
   padding: 0;
   } */

.search-input {
  background: transparent;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTMgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQyICgzNjc4MSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aW9zLXNlYXJjaC1pbnB1dC1pY29uPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9ImNvbXBvbmVudHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJpb3Mtc2VhcmNoLWlucHV0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDguMDAwMDAwLCAtNDMuMDAwMDAwKSIgZmlsbD0iIzdBNzk3QiI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDAuMDAwMDAwLCAzNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNi45OTcyNDgyLDE1LjUwNDE0NjYgQzE3LjA3NzM2NTcsMTUuNTQwNTkzOCAxNy4xNTIyNzMxLDE1LjU5MTYxMjkgMTcuMjE3NzUxNiwxNS42NTcwOTE0IEwyMC42NDk5OTEsMTkuMDg5MzMwOCBDMjAuOTQ0ODQ0OSwxOS4zODQxODQ3IDIwLjk0ODQ3NjQsMTkuODU4NjA2IDIwLjY1MzU0MTIsMjAuMTUzNTQxMiBDMjAuMzYwNjQ4LDIwLjQ0NjQzNDQgMTkuODgxMjcxNiwyMC40NDE5MzE3IDE5LjU4OTMzMDgsMjAuMTQ5OTkxIEwxNi4xNTcwOTE0LDE2LjcxNzc1MTYgQzE2LjA5MTM3LDE2LjY1MjAzMDEgMTYuMDQwMTE3MSwxNi41NzczODc0IDE2LjAwMzQxNDEsMTYuNDk3Nzk5NSBDMTUuMTY3MTY5NCwxNy4xMjcwNDExIDE0LjEyNzEzOTMsMTcuNSAxMywxNy41IEMxMC4yMzg1NzYzLDE3LjUgOCwxNS4yNjE0MjM3IDgsMTIuNSBDOCw5LjczODU3NjI1IDEwLjIzODU3NjMsNy41IDEzLDcuNSBDMTUuNzYxNDIzNyw3LjUgMTgsOS43Mzg1NzYyNSAxOCwxMi41IEMxOCwxMy42Mjc0Njg1IDE3LjYyNjgyMzIsMTQuNjY3Nzc2OCAxNi45OTcyNDgyLDE1LjUwNDE0NjYgWiBNMTMsMTYuNSBDMTUuMjA5MTM5LDE2LjUgMTcsMTQuNzA5MTM5IDE3LDEyLjUgQzE3LDEwLjI5MDg2MSAxNS4yMDkxMzksOC41IDEzLDguNSBDMTAuNzkwODYxLDguNSA5LDEwLjI5MDg2MSA5LDEyLjUgQzksMTQuNzA5MTM5IDEwLjc5MDg2MSwxNi41IDEzLDE2LjUgWiIgaWQ9Imlvcy1zZWFyY2gtaW5wdXQtaWNvbiI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
  background-position: 8px center;
  background-repeat: no-repeat;
  background-size: 13px;
}

.bottom-bar {
  background: #fff;
  border-top: 1px solid #ccc;
}

.page__background {
  background: #ffffff;
}

.grey-text {
  color: #7c7c7d;
}

.legendeq label,
.legendeq span {
  display: block;
  float: left;
  height: 45px;
  width: 25%;
  text-align: center;
  font-size: 10px;
  color: #808080;
}

.modal__content {
  color: #000000;
}
.mapboxgl-popup-content {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 5px;
  border: 0;
}
.mapboxgl-ctrl-scale {
  background-color: rgba(255, 255, 255, 0);
}

ons-progress-circular > svg.progress-circular {
  margin: 0;
  padding: 0;
  width: 15px;
  height: 15px;
}
ons-progress-circular {
  margin: 0;
  padding: 0;
}

ons-toolbar:not([inline]),
ons-bottom-toolbar {
  position: fixed;
}
.toolbar__left {
  width: auto;
  margin: 0;
  padding: 0 15px 0 15px;
}
.toolbar__right {
  width: auto;
  margin: 0;
  padding: 0 15px 0 15px;
}
.toolbar__title {
  font-size: 15px;
}

.page[status-bar-fill] > .page__content {
  top: 0px;
}

ons-toolbar:not([inline]) {
  top: 0;
}

.mapboxgl-ctrl-scale {
  border-color: rgba(51, 51, 51, 0.43);
  border-width: medium 1px 1px;
}
.modal__content {
  vertical-align: top;
}

#legends {
  /* opacity: 0.85; */
  background: rgba(255, 255, 255, 0.4);
}

.legend {
  background: rgba(255, 255, 255, 0.6);
}

.legend > h2 {
  font-size: 16px;
}
